<template>
    <PriceNegotiationForm
        :edit="true"
    />
</template>

<script>
import PriceNegotiationForm from '@/components/PriceNegotiation/PriceNegotiationForm'

export default {
    name: 'PriceNegotiationEdit',
    components: {
        PriceNegotiationForm
    },
    computed: {
        negotiation () {
            return this.$store.state.priceNegotiations.currentNegotiation
        },
        breadcrumbs () {
            return [
                { title: 'Главная', link: '/' },
                { title: 'Перечень согласования цен', link: '/price-negotiations' },
                {
                    title: `Запрос на согласование цен по подрядным работам № ${this.negotiation.number} от ${this.$options.filters.parseDate(this.negotiation.date)}`,
                    link: `/price-negotiations/${this.$route.params.id}`
                },
                { title: 'Редактирование' }
            ]
        }
    }
}
</script>
